export const form56_1_data = [
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2567,
    dateTime: '04/04/2568',
    detail: '/pdf/56-1 One report_รายงานประจำปี 2567 (TH).pdf',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2566,
    dateTime: '19/03/2567',
    detail: '/pdf/STRUCTURESKNT.pdf',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2565,
    dateTime: '20/03/2566',
    detail: '/pdf/F1343T22.zip',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2564,
    dateTime: '16/03/2565',
    detail: '/pdf/F1343T21.zip',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2563,
    dateTime: '18/03/2564',
    detail: '/pdf/F1343T20.zip',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2562,
    dateTime: '27/03/2563',
    detail: '/pdf/F1343T19.zip',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2561,
    dateTime: '26/03/2562',
    detail: '/pdf/F1343T18.zip',
  },
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2560,
    dateTime: '30/03/2561',
    detail: '/pdf/F1343T17.zip',
  },
];

export const form56_1_dataEN = [
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2024,
    dateTime: '04/04/2025',
    detail: '/pdf/56-1 One report_รายงานประจำปี 2567 (EN).pdf',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2023,
    dateTime: '19/03/2024',
    detail: '/pdf/STRUCTURESKNE.pdf',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2022,
    dateTime: '20/03/2023',
    detail: '/pdf/F1343T22.zip',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2021,
    dateTime: '16/03/2022',
    detail: '/pdf/F1343T21.zip',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2020,
    dateTime: '18/03/2021',
    detail: '/pdf/F1343T20.zip',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2019,
    dateTime: '27/03/2020',
    detail: '/pdf/F1343T19.zip',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2018,
    dateTime: '26/03/2019',
    detail: '/pdf/F1343T18.zip',
  },
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2017,
    dateTime: '30/03/2018',
    detail: '/pdf/F1343T17.zip',
  },
];
