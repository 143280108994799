export const dataOneReportBook = [
  {
    companyName: 'บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)',
    year: 2567,
    dateTime: '04/04/2568',
    detail: '/pdf/56-1 One report (e-One report) - TH.pdf',
  },
];

export const dataOneReportBookEN = [
  {
    companyName: 'S.KIJCHAI ENTERPRISE PUBLIC COMPANY LIMITED',
    year: 2024,
    dateTime: '04/04/2025',
    detail: '/pdf/56-1 One report (e-One report) - EN.pdf',
  },
];
